import {Component, computed, EventEmitter, input, Input, Output, TrackByFunction,} from '@angular/core';
import {Rating, WineProduct} from '@core/interfaces/generated/graphql';
import {CountdownLiveComponent} from '../countdown-live/countdown-live.component';
import {ProductSaveLabelComponent} from '../product-save-label/product-save-label.component';
import {ProductRatingComponent} from '../product-rating/product-rating.component';
import {PriceTableComponent} from '../price-table/price-table.component';
import {AsyncPipe, NgClass, NgOptimizedImage} from '@angular/common';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {SafeHtmlPipe} from '@core/pipes/safe-html.pipe';
import {AlgoliaInsightsService} from '@core/services/algolia-insights.service';
import {ProductTagsComponent} from '../product-tags/product-tags.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RouterLink} from '@angular/router';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {ProductTopCtaComponent} from '@layout/components/product/product-top-cta/product-top-cta.component';

@Component({
  selector: 'article[app-product-widget]',
  templateUrl: './product-widget.component.html',
  styleUrls: ['./product-widget.component.scss'],
  standalone: true,
  imports: [NgClass, ExistsPipe, SafeHtmlPipe, AsyncPipe, CountdownLiveComponent, ProductSaveLabelComponent, ProductRatingComponent, PriceTableComponent, NgOptimizedImage, ProductTagsComponent, RouterLink, RouterContextDirective, ProductTopCtaComponent]
})
export class ProductWidgetComponent {

  @Input() sendEvent?: (event: string, payload: any, name: string) => void;
  @Output() productClickEvent: EventEmitter<WineProduct> = new EventEmitter<WineProduct>();

  product = input.required<WineProduct>();
  index = input.required<number>();
  eventSourceName = input.required<string>();
  hideProductDefaultCta = input<boolean>(false);
  queryID = input<string | undefined>(undefined);

  compressedImage = computed(() => this.getProductImageUrl(this.product()))
  ratings = computed(() => this.getRatings(this.product()));
  productUrl = computed(() => '/' + this.product()?.nodePath);
  hitPosition = computed(() => this.index() + 1);
  additionalLinkContext = computed(() => {
    return {hitPosition: this.hitPosition(), eventSourceName: this.eventSourceName()}
  });

  @Input() maxRatings: number;

  constructor(
    private algoliaInsightsService: AlgoliaInsightsService,
    private modal: NgbModal,
  ) {
  }

  private getRatings(product: WineProduct) {
    return product?.meta?.ratings?.slice(0, this.maxRatings) as Rating[];
  }

  private getProductImageUrl(product: WineProduct): string | undefined {
    return product?.image?.small as string | undefined;
  }

  get ratingTracking(): TrackByFunction<Rating> {
    return (index, item) => {
      return item.id;
    };
  }

  addToCart(product: WineProduct) {
    if (this.sendEvent) {
      this.sendEvent('conversion', product, 'Added to cart');
      return;
    }
    this.algoliaInsightsService.clickedAddToCart(product.id);
  }

  productClick(product: WineProduct) {
    this.productClickEvent.emit(product);
    if (this.sendEvent) {
      this.sendEvent('click', product, 'Product Clicked');
      return;
    }
    this.algoliaInsightsService.clickedOnProduct(product.id);
  }

  openEnergyLabelDoc(product: WineProduct) {
    import('../../modals/image-modal/image-modal.component').then(comp => {
      const ref = this.modal.open(comp.ImageModalComponent);
      ref.componentInstance.title = 'Energimærke';
      ref.componentInstance.imgSrc = product.meta?.energyLabelDoc;
    })
  }
}
