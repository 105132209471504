export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  /** All scalar types */
  scalar: any;
};

export type Actions = {
  __typename?: 'Actions';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AddToCartMutation = {
  __typename?: 'AddToCartMutation';
  cart?: Maybe<Cart>;
  message?: Maybe<Scalars['String']>;
};

export type AlgoliaFilter = {
  __typename?: 'AlgoliaFilter';
  algoliaAttribute?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  instantSearchType?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type AlgoliaFiltersAndSorts = {
  __typename?: 'AlgoliaFiltersAndSorts';
  filters?: Maybe<Array<Maybe<AlgoliaFilter>>>;
  sorts?: Maybe<Array<Maybe<AlgoliaSort>>>;
};

export type AlgoliaSort = {
  __typename?: 'AlgoliaSort';
  id?: Maybe<Scalars['ID']>;
  indexName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  code: Scalars['String'];
  value?: Maybe<Scalars['scalar']>;
};

export type AttributeBase = {
  id?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type AuthenticateResponse = {
  __typename?: 'AuthenticateResponse';
  authToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sessionResponse?: Maybe<SessionResponse>;
};

export type Cart = {
  __typename?: 'Cart';
  appliedCartRuleNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingAddress?: Maybe<CustomerProfileAddress>;
  cartId?: Maybe<Scalars['String']>;
  cartRules?: Maybe<Array<Maybe<CartRule>>>;
  couponCode?: Maybe<Scalars['String']>;
  couponDiscountAmount?: Maybe<Scalars['Int']>;
  customerNote?: Maybe<Scalars['String']>;
  flexVoucherAmount?: Maybe<Scalars['Int']>;
  flexVoucherCode?: Maybe<Scalars['String']>;
  flexVoucherUsedAmount?: Maybe<Scalars['Int']>;
  fulfilledRestrictions?: Maybe<Array<Maybe<CartRestriction>>>;
  groupVoucherCode?: Maybe<Scalars['String']>;
  groupVoucherNewsletter?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  isEnPrimeur?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<CartItem>>>;
  itemsTotalBulkDiscount?: Maybe<Scalars['Int']>;
  itemsTotalPrice?: Maybe<Scalars['Int']>;
  itemsTotalQty?: Maybe<Scalars['Int']>;
  newsletter?: Maybe<Scalars['Boolean']>;
  preorderDate?: Maybe<Scalars['String']>;
  requiredRestrictions?: Maybe<Array<Maybe<CartRestriction>>>;
  sameAsBilling?: Maybe<Scalars['Boolean']>;
  shippingAddress?: Maybe<CustomerProfileAddress>;
  shippingMethod?: Maybe<ShippingMethod>;
  /** A key-value array of test cases, used for A/B testing */
  testCases?: Maybe<Scalars['String']>;
  totalDiscount?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalPriceBeforeAllDiscount?: Maybe<Scalars['Int']>;
  totalTax?: Maybe<Scalars['Int']>;
  totalToPayPrice?: Maybe<Scalars['Int']>;
  userVerifications?: Maybe<Array<Maybe<CartRestriction>>>;
};

export type CartItem = {
  __typename?: 'CartItem';
  appliedCartRuleNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  attributeSet?: Maybe<Scalars['String']>;
  compareAtPrice?: Maybe<Scalars['Int']>;
  ctaText?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  discountPercent?: Maybe<Scalars['Int']>;
  fulfilledRestrictions?: Maybe<Array<Maybe<CartRestriction>>>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Image>;
  isFree?: Maybe<Scalars['Boolean']>;
  isEnPrimeur?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  preorderDate?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priceBeforeDiscount?: Maybe<Scalars['Int']>;
  productSubType?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  qtyPrice?: Maybe<Scalars['Int']>;
  qtyPriceBeforeDiscount?: Maybe<Scalars['Int']>;
  requiredRestrictions?: Maybe<Array<Maybe<CartRestriction>>>;
  salableQty?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  stockStatus?: Maybe<CartItemStockStatus>;
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  vismaId?: Maybe<Scalars['ID']>;
};

export enum CartItemStockStatus {
  InStock = 'IN_STOCK',
  NotEnoughStock = 'NOT_ENOUGH_STOCK',
  OutOfStock = 'OUT_OF_STOCK'
}

export enum CartRestriction {
  Min_16Years = 'MIN_16_YEARS',
  Min_18Years = 'MIN_18_YEARS'
}

export type CartRule = {
  __typename?: 'CartRule';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Classics = TaxonomyBase & {
  __typename?: 'Classics';
  /** @deprecated Not used in practice */
  attributeValueCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  vismaReference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type CompanyProfile = {
  __typename?: 'CompanyProfile';
  contactProfile?: Maybe<ContactProfile>;
  groupVoucherCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type ContactProfile = {
  __typename?: 'ContactProfile';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Context = {
  __typename?: 'Context';
  data?: Maybe<ContextType>;
  key?: Maybe<Scalars['String']>;
};

export type ContextType = GroupVoucherCode;

export enum CustomerAccountType {
  Company = 'COMPANY',
  Private = 'PRIVATE'
}

export type CustomerAddressInput = {
  city: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  cvr?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  telephone: Scalars['String'];
  vat?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type CustomerProfile = {
  __typename?: 'CustomerProfile';
  accountType: Scalars['ID'];
  addresses?: Maybe<Array<Maybe<CustomerProfileAddress>>>;
  emailForOrderConfirmation: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  isEmailConfirmed: Scalars['Boolean'];
  superpayUUID?: Maybe<Scalars['String']>;
};

export type CustomerProfileAddress = {
  __typename?: 'CustomerProfileAddress';
  address?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
  customerType?: Maybe<Scalars['String']>;
  cvr?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  groupVoucherCollectiveDelivery?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
};

export type CustomerUpsertInput = {
  accountType: CustomerAccountType;
  billingAddress: CustomerAddressInput;
  email: Scalars['String'];
  fullName: Scalars['String'];
  shippingAddress?: InputMaybe<CustomerAddressInput>;
};

export type Discount = {
  __typename?: 'Discount';
  amount?: Maybe<Scalars['Int']>;
  /** @deprecated Use 'amount' instead */
  amountExVat?: Maybe<Scalars['Int']>;
  amountIncVat?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type Food = {
  __typename?: 'Food';
  iconName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type FormSubmitMessage = {
  __typename?: 'FormSubmitMessage';
  message: Scalars['String'];
  type: Scalars['String'];
};

export type GetAllCartRule = {
  __typename?: 'GetAllCartRule';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CartRule>>>;
  total: Scalars['Int'];
};

export type GetAllClassics = {
  __typename?: 'GetAllClassics';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Classics>>>;
  total: Scalars['Int'];
};

export type GetAllCustomerProfile = {
  __typename?: 'GetAllCustomerProfile';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CustomerProfile>>>;
  total: Scalars['Int'];
};

export type GetAllCustomerProfileAddress = {
  __typename?: 'GetAllCustomerProfileAddress';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<CustomerProfileAddress>>>;
  total: Scalars['Int'];
};

export type GetAllDiscount = {
  __typename?: 'GetAllDiscount';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Discount>>>;
  total: Scalars['Int'];
};

export type GetAllGroupVoucherCode = {
  __typename?: 'GetAllGroupVoucherCode';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<GroupVoucherCode>>>;
  total: Scalars['Int'];
};

export type GetAllPriceTablePricing = {
  __typename?: 'GetAllPriceTablePricing';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<PriceTablePricing>>>;
  total: Scalars['Int'];
};

export type GetAllRating = {
  __typename?: 'GetAllRating';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Rating>>>;
  total: Scalars['Int'];
};

export type GetAllRatingSymbols = {
  __typename?: 'GetAllRatingSymbols';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<RatingSymbols>>>;
  total: Scalars['Int'];
};

export type GetAllReviewer = {
  __typename?: 'GetAllReviewer';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Reviewer>>>;
  total: Scalars['Int'];
};

export type GetAllUnit = {
  __typename?: 'GetAllUnit';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<Unit>>>;
  total: Scalars['Int'];
};

export type GetAllUrlRedirect = {
  __typename?: 'GetAllUrlRedirect';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<UrlRedirect>>>;
  total: Scalars['Int'];
};

export type GetAllWineProduct = {
  __typename?: 'GetAllWineProduct';
  filtered: Scalars['Int'];
  rows?: Maybe<Array<Maybe<WineProduct>>>;
  total: Scalars['Int'];
};

export type GroupVoucherCode = {
  __typename?: 'GroupVoucherCode';
  code: Scalars['String'];
  companyProfile?: Maybe<CompanyProfile>;
  /** Values: 1 or 0 */
  delivery: Scalars['Int'];
  /** Only used if 'delivery' is equal to 1 */
  deliveryAddress?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryCity?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryCompanyName?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryCountry?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryDate?: Maybe<Scalars['Date']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryEan?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryName?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryStreetFloor?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryStreetName?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryStreetNumber?: Maybe<Scalars['String']>;
  /** Only used if 'delivery' is equal to 1 */
  deliveryZip?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  freeShipping: Scalars['Boolean'];
  prices?: Maybe<Array<Maybe<GroupVoucherCodePrice>>>;
  published: Scalars['Boolean'];
  redirectTo?: Maybe<Scalars['String']>;
  showSuggestedRetailPrice?: Maybe<Scalars['Boolean']>;
  showVatText?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  urlKey?: Maybe<Scalars['String']>;
  vismaCampaignId: Scalars['String'];
};

export type GroupVoucherCodeInput = {
  code: Scalars['String'];
  deliveryAddress?: InputMaybe<VoucherAddress>;
  endDate: Scalars['Date'];
  freeShipping: Scalars['Boolean'];
  products: Array<InputMaybe<VoucherProduct>>;
  published: Scalars['Boolean'];
  redirectTo?: InputMaybe<Scalars['String']>;
  showSuggestedRetailPrice?: InputMaybe<Scalars['Boolean']>;
  showVatText?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
  title: Scalars['String'];
  useDeliveryAddress: Scalars['Boolean'];
  vismaCampaignId: Scalars['String'];
};

export type GroupVoucherCodePrice = {
  __typename?: 'GroupVoucherCodePrice';
  id?: Maybe<Scalars['ID']>;
  price: Scalars['String'];
  productId: Scalars['ID'];
  vismaId: Scalars['String'];
};

export type Head = {
  __typename?: 'Head';
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Array<Maybe<HeadLink>>>;
  meta?: Maybe<Array<Maybe<HeadMeta>>>;
  path?: Maybe<Scalars['String']>;
  structuredDataScheme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type HeadLink = {
  __typename?: 'HeadLink';
  charset?: Maybe<Scalars['String']>;
  crossorigin?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  hreflang?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  rev?: Maybe<Scalars['String']>;
  sizes?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HeadMeta = {
  __typename?: 'HeadMeta';
  charset?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  httpEquiv?: Maybe<Scalars['String']>;
  itemprop?: Maybe<Scalars['String']>;
  meta_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  scheme?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  large?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type InputCreateAttribute = {
  code: Scalars['String'];
  value?: InputMaybe<Scalars['scalar']>;
};

export type InputCreateCartRule = {
  amount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InputCreateClassics = {
  attributeValueCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  machineName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  vismaReference?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type InputCreateCompanyProfile = {
  contactProfile?: InputMaybe<InputCreateContactProfile>;
  groupVoucherCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type InputCreateContactProfile = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputCreateCustomerProfile = {
  accountType: Scalars['ID'];
  addresses?: InputMaybe<Array<InputMaybe<InputCreateCustomerProfileAddress>>>;
  emailForOrderConfirmation: Scalars['String'];
  fullName: Scalars['String'];
  isEmailConfirmed: Scalars['Boolean'];
  superpayUUID?: InputMaybe<Scalars['String']>;
};

export type InputCreateCustomerProfileAddress = {
  address?: InputMaybe<Scalars['String']>;
  addressCity?: InputMaybe<Scalars['String']>;
  addressZip?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  customerType?: InputMaybe<Scalars['String']>;
  cvr?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullname?: InputMaybe<Scalars['String']>;
  groupVoucherCollectiveDelivery?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type InputCreateDiscount = {
  amount?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type InputCreateFood = {
  iconName?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type InputCreateGroupVoucherCode = {
  code: Scalars['String'];
  companyProfile?: InputMaybe<InputCreateCompanyProfile>;
  delivery: Scalars['Int'];
  deliveryAddress?: InputMaybe<Scalars['String']>;
  deliveryCity?: InputMaybe<Scalars['String']>;
  deliveryCompanyName?: InputMaybe<Scalars['String']>;
  deliveryCountry?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['Date']>;
  deliveryEan?: InputMaybe<Scalars['String']>;
  deliveryName?: InputMaybe<Scalars['String']>;
  deliveryStreetFloor?: InputMaybe<Scalars['String']>;
  deliveryStreetName?: InputMaybe<Scalars['String']>;
  deliveryStreetNumber?: InputMaybe<Scalars['String']>;
  deliveryZip?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  freeShipping: Scalars['Boolean'];
  prices?: InputMaybe<Array<InputMaybe<InputCreateGroupVoucherCodePrice>>>;
  published: Scalars['Boolean'];
  redirectTo?: InputMaybe<Scalars['String']>;
  showSuggestedRetailPrice?: InputMaybe<Scalars['Boolean']>;
  showVatText?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  urlKey?: InputMaybe<Scalars['String']>;
  vismaCampaignId: Scalars['String'];
};

export type InputCreateGroupVoucherCodePrice = {
  price: Scalars['String'];
  productId: Scalars['ID'];
  vismaId: Scalars['String'];
};

export type InputCreateImage = {
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  large?: InputMaybe<Scalars['String']>;
  original?: InputMaybe<Scalars['String']>;
  small?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type InputCreatePriceTable = {
  defaultQuantity?: InputMaybe<Scalars['String']>;
  pricings?: InputMaybe<Array<InputMaybe<InputCreatePriceTablePricing>>>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type InputCreatePriceTablePricing = {
  amount: Scalars['Int'];
  currency?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<InputCreateDiscount>;
  minQty?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type InputCreateProductAuthor = {
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<Scalars['String']>;
};

export type InputCreateRating = {
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  maxRatingSymbols?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['ID']>;
  rating?: InputMaybe<Scalars['Int']>;
  ratingPoint?: InputMaybe<Scalars['String']>;
  ratingSortWeight?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['String']>;
  reviewer?: InputMaybe<InputCreateReviewer>;
  starCount?: InputMaybe<Scalars['Int']>;
  symbol?: InputMaybe<InputCreateRatingSymbols>;
};

export type InputCreateRatingSymbols = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  machineName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  vismaReference?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type InputCreateReviewer = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isVisibleOnline?: InputMaybe<Scalars['Boolean']>;
  machineName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  ratingType?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  vismaReference?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type InputCreateTopCta = {
  icon?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type InputCreateUnit = {
  id?: InputMaybe<Scalars['String']>;
  machineName?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputCreateUrlRedirect = {
  redirect?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type InputCreateWineMeta = {
  alcohol?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Array<InputMaybe<InputCreateAttribute>>>;
  categoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  classics?: InputMaybe<Array<InputMaybe<InputCreateClassics>>>;
  contentLabel?: InputMaybe<Scalars['String']>;
  contents?: InputMaybe<Scalars['String']>;
  contentsCl?: InputMaybe<Scalars['Int']>;
  countryName?: InputMaybe<Scalars['String']>;
  districtName?: InputMaybe<Scalars['String']>;
  energyLabel?: InputMaybe<Scalars['String']>;
  energyLabelDoc?: InputMaybe<Scalars['String']>;
  foods?: InputMaybe<Array<InputMaybe<InputCreateFood>>>;
  grapes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  port_type?: InputMaybe<Scalars['String']>;
  producerName?: InputMaybe<Scalars['String']>;
  productDataSheet?: InputMaybe<Scalars['String']>;
  productSubType?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  ratings?: InputMaybe<Array<InputMaybe<InputCreateRating>>>;
  savingPotential?: InputMaybe<Scalars['String']>;
  serving?: InputMaybe<Scalars['String']>;
  subDistrictName?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<InputCreateUnit>;
  vintage?: InputMaybe<Scalars['Int']>;
  wineType?: InputMaybe<Scalars['String']>;
};

export type InputCreateWineProduct = {
  availableFrom?: InputMaybe<Scalars['DateTime']>;
  baPercentage?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  canBeAddedToCart?: InputMaybe<Scalars['Boolean']>;
  closure?: InputMaybe<Scalars['String']>;
  commercePrice: Scalars['Int'];
  costPrice?: InputMaybe<Scalars['Int']>;
  ctaHidePrice?: InputMaybe<Scalars['Boolean']>;
  ctaLink?: InputMaybe<Scalars['String']>;
  ctaStockText?: InputMaybe<Scalars['String']>;
  ctaTitle?: InputMaybe<Scalars['String']>;
  defaultQuantity?: InputMaybe<Scalars['Int']>;
  hideDiscountCountdown?: InputMaybe<Scalars['Boolean']>;
  hidePriceTable?: InputMaybe<Scalars['Boolean']>;
  hideReview?: InputMaybe<Scalars['Boolean']>;
  hideShippingCountdown?: InputMaybe<Scalars['Boolean']>;
  hideSupervinAdvantages?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<InputCreateImage>;
  imagePath?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<InputCreateImage>>>;
  isB2b?: InputMaybe<Scalars['Boolean']>;
  isBundleProduct?: InputMaybe<Scalars['Boolean']>;
  isLeftover?: InputMaybe<Scalars['Boolean']>;
  isSecret?: InputMaybe<Scalars['Boolean']>;
  longText?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<InputCreateWineMeta>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  minimumQuantity?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nodePath?: InputMaybe<Scalars['String']>;
  nonAlcoholic?: InputMaybe<Scalars['Boolean']>;
  organic?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Scalars['String']>;
  pageWhitelist?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdfText?: InputMaybe<Scalars['String']>;
  preorderDate?: InputMaybe<Scalars['String']>;
  priceTable: InputCreatePriceTable;
  productAuthor?: InputMaybe<InputCreateProductAuthor>;
  productId?: InputMaybe<Scalars['ID']>;
  productImageBadgeType?: InputMaybe<Scalars['String']>;
  promoteFrontPage?: InputMaybe<Scalars['Boolean']>;
  published?: InputMaybe<Scalars['Boolean']>;
  searchSortWeight?: InputMaybe<Scalars['String']>;
  searchWords?: InputMaybe<Scalars['String']>;
  secretKey?: InputMaybe<Scalars['String']>;
  showSavings?: InputMaybe<Scalars['Boolean']>;
  sku: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  supplierName?: InputMaybe<Scalars['String']>;
  supplierPhoneNumber?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  topCta?: InputMaybe<InputCreateTopCta>;
  typeId?: InputMaybe<ProductType>;
  urlRedirects?: InputMaybe<Array<InputMaybe<InputCreateUrlRedirect>>>;
  vimeoId?: InputMaybe<Scalars['String']>;
  vismaProductNumber: Scalars['String'];
};

export type InputUpdateAttribute = {
  code?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['scalar']>;
};

export type InputUpdateCart = {
  appliedCartRuleNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  billingAddress?: InputMaybe<InputUpdateCustomerProfileAddress>;
  cartId?: InputMaybe<Scalars['String']>;
  cartRules?: InputMaybe<Array<InputMaybe<InputUpdateCartRule>>>;
  couponCode?: InputMaybe<Scalars['String']>;
  couponDiscountAmount?: InputMaybe<Scalars['Int']>;
  customerNote?: InputMaybe<Scalars['String']>;
  flexVoucherAmount?: InputMaybe<Scalars['Int']>;
  flexVoucherCode?: InputMaybe<Scalars['String']>;
  flexVoucherUsedAmount?: InputMaybe<Scalars['Int']>;
  fulfilledRestrictions?: InputMaybe<Array<InputMaybe<CartRestriction>>>;
  groupVoucherCode?: InputMaybe<Scalars['String']>;
  groupVoucherNewsletter?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<InputMaybe<InputUpdateCartItem>>>;
  itemsTotalBulkDiscount?: InputMaybe<Scalars['Int']>;
  itemsTotalPrice?: InputMaybe<Scalars['Int']>;
  itemsTotalQty?: InputMaybe<Scalars['Int']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  preorderDate?: InputMaybe<Scalars['String']>;
  requiredRestrictions?: InputMaybe<Array<InputMaybe<CartRestriction>>>;
  sameAsBilling?: InputMaybe<Scalars['Boolean']>;
  shippingAddress?: InputMaybe<InputUpdateCustomerProfileAddress>;
  shippingMethod?: InputMaybe<InputUpdateShippingMethod>;
  testCases?: InputMaybe<Scalars['String']>;
  totalDiscount?: InputMaybe<Scalars['Int']>;
  totalPrice?: InputMaybe<Scalars['Int']>;
  totalPriceBeforeAllDiscount?: InputMaybe<Scalars['Int']>;
  totalTax?: InputMaybe<Scalars['Int']>;
  totalToPayPrice?: InputMaybe<Scalars['Int']>;
  userVerifications?: InputMaybe<Array<InputMaybe<CartRestriction>>>;
};

export type InputUpdateCartItem = {
  appliedCartRuleNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attributeSet?: InputMaybe<Scalars['String']>;
  compareAtPrice?: InputMaybe<Scalars['Int']>;
  ctaText?: InputMaybe<Scalars['String']>;
  discountAmount?: InputMaybe<Scalars['Int']>;
  discountPercent?: InputMaybe<Scalars['Int']>;
  fulfilledRestrictions?: InputMaybe<Array<InputMaybe<CartRestriction>>>;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<InputUpdateImage>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  preorderDate?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priceBeforeDiscount?: InputMaybe<Scalars['Int']>;
  productSubType?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Int']>;
  qtyPrice?: InputMaybe<Scalars['Int']>;
  qtyPriceBeforeDiscount?: InputMaybe<Scalars['Int']>;
  requiredRestrictions?: InputMaybe<Array<InputMaybe<CartRestriction>>>;
  salableQty?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Scalars['String']>;
  stockStatus?: InputMaybe<CartItemStockStatus>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  vismaId?: InputMaybe<Scalars['ID']>;
};

export type InputUpdateCartRule = {
  amount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InputUpdateClassics = {
  attributeValueCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  machineName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  vismaReference?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type InputUpdateCompanyProfile = {
  contactProfile?: InputMaybe<InputUpdateContactProfile>;
  groupVoucherCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type InputUpdateContactProfile = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateCustomerProfile = {
  accountType?: InputMaybe<Scalars['ID']>;
  addresses?: InputMaybe<Array<InputMaybe<InputUpdateCustomerProfileAddress>>>;
  emailForOrderConfirmation?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isEmailConfirmed?: InputMaybe<Scalars['Boolean']>;
  superpayUUID?: InputMaybe<Scalars['String']>;
};

export type InputUpdateCustomerProfileAddress = {
  address?: InputMaybe<Scalars['String']>;
  addressCity?: InputMaybe<Scalars['String']>;
  addressZip?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  customerType?: InputMaybe<Scalars['String']>;
  cvr?: InputMaybe<Scalars['String']>;
  ean?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullname?: InputMaybe<Scalars['String']>;
  groupVoucherCollectiveDelivery?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type InputUpdateDiscount = {
  amount?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type InputUpdateGroupVoucherCode = {
  code?: InputMaybe<Scalars['String']>;
  companyProfile?: InputMaybe<InputUpdateCompanyProfile>;
  delivery?: InputMaybe<Scalars['Int']>;
  deliveryAddress?: InputMaybe<Scalars['String']>;
  deliveryCity?: InputMaybe<Scalars['String']>;
  deliveryCompanyName?: InputMaybe<Scalars['String']>;
  deliveryCountry?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['Date']>;
  deliveryEan?: InputMaybe<Scalars['String']>;
  deliveryName?: InputMaybe<Scalars['String']>;
  deliveryStreetFloor?: InputMaybe<Scalars['String']>;
  deliveryStreetName?: InputMaybe<Scalars['String']>;
  deliveryStreetNumber?: InputMaybe<Scalars['String']>;
  deliveryZip?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  freeShipping?: InputMaybe<Scalars['Boolean']>;
  prices?: InputMaybe<Array<InputMaybe<InputUpdateGroupVoucherCodePrice>>>;
  published?: InputMaybe<Scalars['Boolean']>;
  redirectTo?: InputMaybe<Scalars['String']>;
  showSuggestedRetailPrice?: InputMaybe<Scalars['Boolean']>;
  showVatText?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  urlKey?: InputMaybe<Scalars['String']>;
  vismaCampaignId?: InputMaybe<Scalars['String']>;
};

export type InputUpdateGroupVoucherCodePrice = {
  price?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['ID']>;
  vismaId?: InputMaybe<Scalars['String']>;
};

export type InputUpdateImage = {
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  large?: InputMaybe<Scalars['String']>;
  original?: InputMaybe<Scalars['String']>;
  small?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type InputUpdatePriceTable = {
  defaultQuantity?: InputMaybe<Scalars['String']>;
  pricings?: InputMaybe<Array<InputMaybe<InputUpdatePriceTablePricing>>>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type InputUpdatePriceTablePricing = {
  amount?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<InputUpdateDiscount>;
  minQty?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type InputUpdateProductAuthor = {
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<Scalars['String']>;
};

export type InputUpdateRating = {
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  maxRatingSymbols?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['ID']>;
  rating?: InputMaybe<Scalars['Int']>;
  ratingPoint?: InputMaybe<Scalars['String']>;
  ratingSortWeight?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['String']>;
  reviewer?: InputMaybe<Scalars['ID']>;
  starCount?: InputMaybe<Scalars['Int']>;
  symbol?: InputMaybe<Scalars['ID']>;
};

export type InputUpdateRatingSymbols = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  machineName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  vismaReference?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type InputUpdateReviewer = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  isVisibleOnline?: InputMaybe<Scalars['Boolean']>;
  machineName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  ratingType?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  vismaReference?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type InputUpdateShippingMethod = {
  carrier?: InputMaybe<Scalars['String']>;
  carrier_logo?: InputMaybe<Scalars['String']>;
  carrier_title?: InputMaybe<Scalars['String']>;
  deliveryComment?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  dontDeliverDates?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  fastestDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priceDescription?: InputMaybe<Scalars['String']>;
  showCustomDatePicker?: InputMaybe<Scalars['Boolean']>;
  showWhereToLeavePackage?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateTopCta = {
  icon?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type InputUpdateUnit = {
  id?: InputMaybe<Scalars['String']>;
  machineName?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InputUpdateUrlRedirect = {
  redirect?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type InputUpdateWineMeta = {
  alcohol?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Array<InputMaybe<InputUpdateAttribute>>>;
  categoryNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  classics?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentLabel?: InputMaybe<Scalars['String']>;
  contents?: InputMaybe<Scalars['String']>;
  contentsCl?: InputMaybe<Scalars['Int']>;
  countryName?: InputMaybe<Scalars['String']>;
  districtName?: InputMaybe<Scalars['String']>;
  energyLabel?: InputMaybe<Scalars['String']>;
  energyLabelDoc?: InputMaybe<Scalars['String']>;
  foods?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  grapes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  port_type?: InputMaybe<Scalars['String']>;
  producerName?: InputMaybe<Scalars['String']>;
  productDataSheet?: InputMaybe<Scalars['String']>;
  productSubType?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  ratings?: InputMaybe<Array<InputMaybe<InputUpdateRating>>>;
  savingPotential?: InputMaybe<Scalars['String']>;
  serving?: InputMaybe<Scalars['String']>;
  subDistrictName?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<InputUpdateUnit>;
  vintage?: InputMaybe<Scalars['Int']>;
  wineType?: InputMaybe<Scalars['String']>;
};

export type InputUpdateWineProduct = {
  availableFrom?: InputMaybe<Scalars['DateTime']>;
  baPercentage?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  canBeAddedToCart?: InputMaybe<Scalars['Boolean']>;
  closure?: InputMaybe<Scalars['String']>;
  commercePrice?: InputMaybe<Scalars['Int']>;
  costPrice?: InputMaybe<Scalars['Int']>;
  ctaHidePrice?: InputMaybe<Scalars['Boolean']>;
  ctaLink?: InputMaybe<Scalars['String']>;
  ctaStockText?: InputMaybe<Scalars['String']>;
  ctaTitle?: InputMaybe<Scalars['String']>;
  defaultQuantity?: InputMaybe<Scalars['Int']>;
  hideDiscountCountdown?: InputMaybe<Scalars['Boolean']>;
  hidePriceTable?: InputMaybe<Scalars['Boolean']>;
  hideReview?: InputMaybe<Scalars['Boolean']>;
  hideShippingCountdown?: InputMaybe<Scalars['Boolean']>;
  hideSupervinAdvantages?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<InputUpdateImage>;
  imagePath?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<InputUpdateImage>>>;
  isB2b?: InputMaybe<Scalars['Boolean']>;
  isBundleProduct?: InputMaybe<Scalars['Boolean']>;
  isLeftover?: InputMaybe<Scalars['Boolean']>;
  isSecret?: InputMaybe<Scalars['Boolean']>;
  longText?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<InputUpdateWineMeta>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  minimumQuantity?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nodePath?: InputMaybe<Scalars['String']>;
  nonAlcoholic?: InputMaybe<Scalars['Boolean']>;
  organic?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Scalars['String']>;
  pageWhitelist?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdfText?: InputMaybe<Scalars['String']>;
  preorderDate?: InputMaybe<Scalars['String']>;
  priceTable?: InputMaybe<InputUpdatePriceTable>;
  productAuthor?: InputMaybe<InputUpdateProductAuthor>;
  productId?: InputMaybe<Scalars['ID']>;
  productImageBadgeType?: InputMaybe<Scalars['String']>;
  promoteFrontPage?: InputMaybe<Scalars['Boolean']>;
  published?: InputMaybe<Scalars['Boolean']>;
  searchSortWeight?: InputMaybe<Scalars['String']>;
  searchWords?: InputMaybe<Scalars['String']>;
  secretKey?: InputMaybe<Scalars['String']>;
  showSavings?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  supplierName?: InputMaybe<Scalars['String']>;
  supplierPhoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topCta?: InputMaybe<InputUpdateTopCta>;
  typeId?: InputMaybe<ProductType>;
  urlRedirects?: InputMaybe<Array<InputMaybe<InputUpdateUrlRedirect>>>;
  vimeoId?: InputMaybe<Scalars['String']>;
  vismaProductNumber?: InputMaybe<Scalars['String']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  compareAtPriceVismaFormat?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  itemType?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  productName?: Maybe<Scalars['String']>;
  qtyPriceVismaFormat?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  totalPriceVismaFormat?: Maybe<Scalars['Int']>;
};

export type ListPage = PageInfoInterface & {
  __typename?: 'ListPage';
  objectIds: Array<Maybe<ListPageObjectIds>>;
  storyblok?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  types: Array<Maybe<ListPageTypeTypes>>;
};

export type ListPageObjectIds = {
  __typename?: 'ListPageObjectIds';
  objectId: Scalars['Int'];
  weight: Scalars['Int'];
};

export type ListPageTypeTypes = {
  __typename?: 'ListPageTypeTypes';
  attribute: Scalars['String'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addToCart?: Maybe<AddToCartMutation>;
  /** Apply Coupon Code to the cart */
  applyCouponCode?: Maybe<Cart>;
  /** Apply FlexVoucher to the cart */
  applyFlexVoucher?: Maybe<Cart>;
  /** Apply group voucher to the cart */
  applyGroupVoucher?: Maybe<SessionResponse>;
  authenticateV2?: Maybe<AuthenticateResponse>;
  changePassword?: Maybe<FormSubmitMessage>;
  confirmAccount?: Maybe<AuthenticateResponse>;
  /** Creates a new CartRule */
  createCartRule?: Maybe<CartRule>;
  /** Creates a new Classics */
  createClassics?: Maybe<Classics>;
  /** Creates a new CustomerProfile */
  createCustomerProfile?: Maybe<CustomerProfile>;
  /** Creates a new CustomerProfileAddress */
  createCustomerProfileAddress?: Maybe<CustomerProfileAddress>;
  /** Creates a new Discount */
  createDiscount?: Maybe<Discount>;
  /** Creates a new GroupVoucherCode */
  createGroupVoucherCode?: Maybe<GroupVoucherCode>;
  /** Creates a new PriceTablePricing */
  createPriceTablePricing?: Maybe<PriceTablePricing>;
  /** Creates a new Rating */
  createRating?: Maybe<Rating>;
  /** Creates a new RatingSymbols */
  createRatingSymbols?: Maybe<RatingSymbols>;
  /** Creates a new Reviewer */
  createReviewer?: Maybe<Reviewer>;
  /** Creates a new Unit */
  createUnit?: Maybe<Unit>;
  /** Creates a new UrlRedirect */
  createUrlRedirect?: Maybe<UrlRedirect>;
  /** Creates a new WineProduct */
  createWineProduct?: Maybe<WineProduct>;
  /** Deletes a CartRule by id */
  deleteCartRule?: Maybe<Scalars['String']>;
  /** Deletes a Classics by id */
  deleteClassics?: Maybe<Scalars['String']>;
  /** Deletes a CustomerProfile by id */
  deleteCustomerProfile?: Maybe<Scalars['String']>;
  /** Deletes a CustomerProfileAddress by id */
  deleteCustomerProfileAddress?: Maybe<Scalars['String']>;
  /** Deletes a Discount by id */
  deleteDiscount?: Maybe<Scalars['String']>;
  /** Deletes a GroupVoucherCode by id */
  deleteGroupVoucherCode?: Maybe<Scalars['String']>;
  /** Deletes a PriceTablePricing by id */
  deletePriceTablePricing?: Maybe<Scalars['String']>;
  /** Deletes a Rating by id */
  deleteRating?: Maybe<Scalars['String']>;
  /** Deletes a RatingSymbols by id */
  deleteRatingSymbols?: Maybe<Scalars['String']>;
  /** Deletes a Reviewer by id */
  deleteReviewer?: Maybe<Scalars['String']>;
  /** Deletes a Unit by id */
  deleteUnit?: Maybe<Scalars['String']>;
  /** Deletes a UrlRedirect by id */
  deleteUrlRedirect?: Maybe<Scalars['String']>;
  /** Deletes a WineProduct by id */
  deleteWineProduct?: Maybe<Scalars['String']>;
  hubSpotFormEvent?: Maybe<Scalars['String']>;
  /** Places an order from cart id */
  placeOrder?: Maybe<RedirectObject>;
  register?: Maybe<AuthenticateResponse>;
  /** Remove Flex Voucher from the cart */
  removeCouponCodeFromCart?: Maybe<Cart>;
  /** Remove Flex Voucher from the cart */
  removeFlexVoucherFromCart?: Maybe<Cart>;
  removeFromCart?: Maybe<Cart>;
  /** Remove group voucher from the cart */
  removeGroupVoucherFromCart?: Maybe<SessionResponse>;
  saveCustomerNote?: Maybe<Scalars['String']>;
  saveRRWeb?: Maybe<Scalars['String']>;
  saveShippingInfo?: Maybe<Cart>;
  saveShippingMethod?: Maybe<Cart>;
  sendAccountConfirmationEmail?: Maybe<FormSubmitMessage>;
  sendRpTokenOnEmail?: Maybe<FormSubmitMessage>;
  signOutV2?: Maybe<SessionResponse>;
  syncOrderToVisma?: Maybe<Scalars['String']>;
  trackPurchaseEvent?: Maybe<Scalars['String']>;
  trackingEvent?: Maybe<Scalars['String']>;
  updateCart?: Maybe<UpdateCartMutation>;
  /** Updates a CartRule  by id */
  updateCartRule?: Maybe<CartRule>;
  /** Updates a Classics  by id */
  updateClassics?: Maybe<Classics>;
  /** Updates a CustomerProfile  by id */
  updateCustomerProfile?: Maybe<CustomerProfile>;
  /** Updates a CustomerProfileAddress  by id */
  updateCustomerProfileAddress?: Maybe<CustomerProfileAddress>;
  /** Updates a Discount  by id */
  updateDiscount?: Maybe<Discount>;
  /** Updates a GroupVoucherCode  by id */
  updateGroupVoucherCode?: Maybe<GroupVoucherCode>;
  updateHubspotDeal?: Maybe<Scalars['String']>;
  /** Updates Order status */
  updateOrderStatus?: Maybe<Scalars['String']>;
  /** Updates a PriceTablePricing  by id */
  updatePriceTablePricing?: Maybe<PriceTablePricing>;
  /** Updates a Rating  by id */
  updateRating?: Maybe<Rating>;
  /** Updates a RatingSymbols  by id */
  updateRatingSymbols?: Maybe<RatingSymbols>;
  /** Updates a Reviewer  by id */
  updateReviewer?: Maybe<Reviewer>;
  /** Updates the given session */
  updateSession?: Maybe<SessionResponse>;
  updateSessionWithCustomerToken?: Maybe<SessionResponse>;
  /** Updates Supervin stock */
  updateSupervinStock?: Maybe<Scalars['String']>;
  /** Updates a Unit  by id */
  updateUnit?: Maybe<Unit>;
  /** Updates a UrlRedirect  by id */
  updateUrlRedirect?: Maybe<UrlRedirect>;
  /** Updates a WineProduct  by id */
  updateWineProduct?: Maybe<WineProduct>;
  /** Upsert Campaign */
  upsertCampaign?: Maybe<Scalars['String']>;
  /** Updating or creating a customer based on email as unique key */
  upsertCustomer?: Maybe<Scalars['String']>;
  /** Upsert Group Voucher */
  upsertGroupVoucher?: Maybe<Scalars['String']>;
  /** Upserts a new WineProduct */
  upsertWineProduct?: Maybe<WineProduct>;
  /** Upserts by SKU a new WineProduct */
  upsertWineProductBySku?: Maybe<WineProduct>;
};


export type MutationAddToCartArgs = {
  customPrice?: InputMaybe<Scalars['Int']>;
  eventSourceName?: InputMaybe<Scalars['String']>;
  hitPosition?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  qty: Scalars['Int'];
  vismaId?: InputMaybe<Scalars['String']>;
};


export type MutationApplyCouponCodeArgs = {
  couponCode: Scalars['String'];
};


export type MutationApplyFlexVoucherArgs = {
  flexVoucherCode: Scalars['String'];
};


export type MutationApplyGroupVoucherArgs = {
  groupVoucherCode: Scalars['String'];
};


export type MutationAuthenticateV2Args = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  email?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  resetToken?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmAccountArgs = {
  confirmationKey: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCreateCartRuleArgs = {
  data: InputCreateCartRule;
};


export type MutationCreateClassicsArgs = {
  data: InputCreateClassics;
};


export type MutationCreateCustomerProfileArgs = {
  data: InputCreateCustomerProfile;
};


export type MutationCreateCustomerProfileAddressArgs = {
  data: InputCreateCustomerProfileAddress;
};


export type MutationCreateDiscountArgs = {
  data: InputCreateDiscount;
};


export type MutationCreateGroupVoucherCodeArgs = {
  data: InputCreateGroupVoucherCode;
};


export type MutationCreatePriceTablePricingArgs = {
  data: InputCreatePriceTablePricing;
};


export type MutationCreateRatingArgs = {
  data: InputCreateRating;
};


export type MutationCreateRatingSymbolsArgs = {
  data: InputCreateRatingSymbols;
};


export type MutationCreateReviewerArgs = {
  data: InputCreateReviewer;
};


export type MutationCreateUnitArgs = {
  data: InputCreateUnit;
};


export type MutationCreateUrlRedirectArgs = {
  data: InputCreateUrlRedirect;
};


export type MutationCreateWineProductArgs = {
  data: InputCreateWineProduct;
};


export type MutationDeleteCartRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteClassicsArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerProfileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerProfileAddressArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupVoucherCodeArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePriceTablePricingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRatingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRatingSymbolsArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReviewerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUrlRedirectArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWineProductArgs = {
  id: Scalars['ID'];
};


export type MutationHubSpotFormEventArgs = {
  data: Scalars['String'];
  formName: Scalars['String'];
};


export type MutationPlaceOrderArgs = {
  groupVoucherNewsletter?: InputMaybe<Scalars['Boolean']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  paymentMethod: Scalars['String'];
  supervinEventPermission?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRegisterArgs = {
  emailForOrderConfirmation: Scalars['String'];
  fullName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveFromCartArgs = {
  sku: Scalars['String'];
};


export type MutationSaveCustomerNoteArgs = {
  customerNote: Scalars['String'];
};


export type MutationSaveRrWebArgs = {
  batch_id: Scalars['Int'];
  events: Scalars['String'];
  hash_map: Scalars['String'];
};


export type MutationSaveShippingInfoArgs = {
  data: InputUpdateCart;
};


export type MutationSaveShippingMethodArgs = {
  deliveryComment?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  shippingMethod: Scalars['String'];
};


export type MutationSendAccountConfirmationEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendRpTokenOnEmailArgs = {
  email: Scalars['String'];
  type: Scalars['String'];
};


export type MutationSyncOrderToVismaArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  sandbox?: InputMaybe<Scalars['Boolean']>;
  webOrderId: Scalars['String'];
};


export type MutationTrackPurchaseEventArgs = {
  maskedCartId: Scalars['ID'];
};


export type MutationTrackingEventArgs = {
  data: Scalars['String'];
  event: Scalars['String'];
};


export type MutationUpdateCartArgs = {
  qty: Scalars['Int'];
  sku: Scalars['String'];
};


export type MutationUpdateCartRuleArgs = {
  data: InputUpdateCartRule;
  id: Scalars['ID'];
};


export type MutationUpdateClassicsArgs = {
  data: InputUpdateClassics;
  id: Scalars['ID'];
};


export type MutationUpdateCustomerProfileArgs = {
  data: InputUpdateCustomerProfile;
  id: Scalars['ID'];
};


export type MutationUpdateCustomerProfileAddressArgs = {
  data: InputUpdateCustomerProfileAddress;
  id: Scalars['ID'];
};


export type MutationUpdateDiscountArgs = {
  data: InputUpdateDiscount;
  id: Scalars['ID'];
};


export type MutationUpdateGroupVoucherCodeArgs = {
  data: InputUpdateGroupVoucherCode;
  id: Scalars['ID'];
};


export type MutationUpdateHubspotDealArgs = {
  dealStageStep: Scalars['Int'];
};


export type MutationUpdateOrderStatusArgs = {
  orderId: Scalars['String'];
  status: OrderStatus;
};


export type MutationUpdatePriceTablePricingArgs = {
  data: InputUpdatePriceTablePricing;
  id: Scalars['ID'];
};


export type MutationUpdateRatingArgs = {
  data: InputUpdateRating;
  id: Scalars['ID'];
};


export type MutationUpdateRatingSymbolsArgs = {
  data: InputUpdateRatingSymbols;
  id: Scalars['ID'];
};


export type MutationUpdateReviewerArgs = {
  data: InputUpdateReviewer;
  id: Scalars['ID'];
};


export type MutationUpdateSessionArgs = {
  cartId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateSessionWithCustomerTokenArgs = {
  cartId?: InputMaybe<Scalars['ID']>;
  customerToken?: InputMaybe<Scalars['String']>;
  mergeCart?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateSupervinStockArgs = {
  stock: Scalars['Int'];
  vismaId: Scalars['String'];
};


export type MutationUpdateUnitArgs = {
  data: InputUpdateUnit;
  id: Scalars['ID'];
};


export type MutationUpdateUrlRedirectArgs = {
  data: InputUpdateUrlRedirect;
  id: Scalars['ID'];
};


export type MutationUpdateWineProductArgs = {
  data: InputUpdateWineProduct;
  id: Scalars['ID'];
};


export type MutationUpsertCampaignArgs = {
  campaignName: Scalars['String'];
  vismaIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationUpsertCustomerArgs = {
  data: CustomerUpsertInput;
  email: Scalars['String'];
};


export type MutationUpsertGroupVoucherArgs = {
  groupVoucher: GroupVoucherCodeInput;
};


export type MutationUpsertWineProductArgs = {
  data: InputUpdateWineProduct;
  vismaId: Scalars['String'];
};


export type MutationUpsertWineProductBySkuArgs = {
  data: InputUpdateWineProduct;
  sku: Scalars['String'];
};

export type NodeBase = {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  nodePath?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  urlRedirects?: Maybe<Array<Maybe<UrlRedirect>>>;
};

export type NotFoundPage = {
  __typename?: 'NotFoundPage';
  path?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  couponCode?: Maybe<Scalars['String']>;
  customerUUID?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryMessage?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<Scalars['String']>;
  groupVoucherCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isSuperpay?: Maybe<Scalars['Boolean']>;
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
  maskedCartId?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  placed?: Maybe<Scalars['DateTime']>;
  shippingPriceVismaFormat?: Maybe<Scalars['Int']>;
  signedUpForNewsletter?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  taxVismaFormat?: Maybe<Scalars['Int']>;
  /** @deprecated not used anymore */
  ticketsSent?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['String']>;
  totalVismaFormat?: Maybe<Scalars['Int']>;
  userMail?: Maybe<Scalars['String']>;
  webOrderId: Scalars['ID'];
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export type PageBreadcrumb = {
  __typename?: 'PageBreadcrumb';
  label: Scalars['String'];
  path: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  actions?: Maybe<Array<Maybe<Actions>>>;
  breadcrumb?: Maybe<Array<Maybe<PageBreadcrumb>>>;
  breadcrumbStyle: Scalars['String'];
  context?: Maybe<Array<Maybe<Context>>>;
  head?: Maybe<Head>;
  id?: Maybe<Scalars['String']>;
  pageData?: Maybe<PageInfoData>;
  path?: Maybe<Scalars['String']>;
  redirect?: Maybe<Redirect>;
  type?: Maybe<PageInfoType>;
};

export type PageInfoData = ListPage | NotFoundPage | ProductPage | Storyblok;

export type PageInfoInterface = {
  storyblok?: Maybe<Scalars['String']>;
};

export enum PageInfoType {
  ListPage = 'LIST_PAGE',
  NotFound = 'NOT_FOUND',
  ProductPage = 'PRODUCT_PAGE',
  SearchPageInfo = 'SEARCH_PAGE_INFO',
  StoryblokPage = 'STORYBLOK_PAGE'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  description?: Maybe<Scalars['String']>;
  icons?: Maybe<Array<Maybe<Image>>>;
  id?: Maybe<Scalars['ID']>;
  method?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PriceTable = {
  __typename?: 'PriceTable';
  /** @deprecated Use defaultQuantity on the product instead */
  defaultQuantity?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pricings?: Maybe<Array<Maybe<PriceTablePricing>>>;
  productId?: Maybe<Scalars['Int']>;
};

export type PriceTablePricing = {
  __typename?: 'PriceTablePricing';
  amount: Scalars['Int'];
  amountIncVat?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discount?: Maybe<Discount>;
  id?: Maybe<Scalars['String']>;
  maxQty?: Maybe<Scalars['Int']>;
  minQty?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};

export type ProductAuthor = {
  __typename?: 'ProductAuthor';
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
};

export type ProductBase = {
  canBeAddedToCart?: Maybe<Scalars['Boolean']>;
  commercePrice: Scalars['Int'];
  costPrice?: Maybe<Scalars['Int']>;
  ctaHidePrice?: Maybe<Scalars['Boolean']>;
  ctaLink?: Maybe<Scalars['String']>;
  ctaStockText?: Maybe<Scalars['String']>;
  ctaTitle?: Maybe<Scalars['String']>;
  defaultQuantity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** @deprecated Use 'images' instead */
  image?: Maybe<Image>;
  /** @deprecated Not used in Practice */
  imagePath?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isTicketsEnabled?: Maybe<Scalars['Boolean']>;
  isEnPrimeur?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use 'body' instead */
  longText?: Maybe<Scalars['String']>;
  longTrimmedText?: Maybe<Scalars['String']>;
  /** @deprecated Use 'title' instead! */
  name?: Maybe<Scalars['String']>;
  preorderDate?: Maybe<Scalars['String']>;
  priceTable: PriceTable;
  productId?: Maybe<Scalars['ID']>;
  shortText?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
};

export type ProductPage = PageInfoInterface & {
  __typename?: 'ProductPage';
  product?: Maybe<WineProduct>;
  storyblok?: Maybe<Scalars['String']>;
};

export type ProductTag = {
  __typename?: 'ProductTag';
  altText?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export enum ProductType {
  Giftcard = 'GIFTCARD',
  Simple = 'SIMPLE',
  Virtual = 'VIRTUAL'
}

export type Query = {
  __typename?: 'Query';
  _service?: Maybe<_Service>;
  /** Get a list of Algolia filters */
  getAlgoliaFiltersAndSorts?: Maybe<AlgoliaFiltersAndSorts>;
  /** Returns all of CartRule */
  getAllCartRule?: Maybe<GetAllCartRule>;
  /** Returns all of Classics */
  getAllClassics?: Maybe<GetAllClassics>;
  /** Returns all of CustomerProfile */
  getAllCustomerProfile?: Maybe<GetAllCustomerProfile>;
  /** Returns all of CustomerProfileAddress */
  getAllCustomerProfileAddress?: Maybe<GetAllCustomerProfileAddress>;
  /** Returns all of Discount */
  getAllDiscount?: Maybe<GetAllDiscount>;
  /** Returns all of GroupVoucherCode */
  getAllGroupVoucherCode?: Maybe<GetAllGroupVoucherCode>;
  /** Returns all of PriceTablePricing */
  getAllPriceTablePricing?: Maybe<GetAllPriceTablePricing>;
  /** Returns all of Rating */
  getAllRating?: Maybe<GetAllRating>;
  /** Returns all of RatingSymbols */
  getAllRatingSymbols?: Maybe<GetAllRatingSymbols>;
  /** Returns all of Reviewer */
  getAllReviewer?: Maybe<GetAllReviewer>;
  /** Returns all of Unit */
  getAllUnit?: Maybe<GetAllUnit>;
  /** Returns all of UrlRedirect */
  getAllUrlRedirect?: Maybe<GetAllUrlRedirect>;
  /** Returns all of WineProduct */
  getAllWineProduct?: Maybe<GetAllWineProduct>;
  getCart?: Maybe<Cart>;
  getCityByZipcode?: Maybe<Scalars['String']>;
  getCustomerUuidByEmail?: Maybe<Scalars['String']>;
  /** Get group voucher by group voucher code */
  getGroupVoucherByCode?: Maybe<GroupVoucherCode>;
  /** Get group voucher from the cart */
  getGroupVoucherFromCart?: Maybe<GroupVoucherCode>;
  getMagentoOrder?: Maybe<Order>;
  getOrders?: Maybe<Array<Maybe<Order>>>;
  getPageInfo?: Maybe<PageInfo>;
  getPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  getProductByPath?: Maybe<WineProduct>;
  getProductByVismaId?: Maybe<WineProduct>;
  /** @deprecated Use GetProductsByVismaIdsQuery instead */
  getProductsByIds?: Maybe<Array<Maybe<WineProduct>>>;
  getProductsByVismaIds?: Maybe<Array<Maybe<WineProduct>>>;
  getSession?: Maybe<SessionResponse>;
  getShippingMethods?: Maybe<Array<Maybe<ShippingMethod>>>;
  getSitemap?: Maybe<Array<Maybe<SitemapEntry>>>;
  getStoryblokPreviewToken?: Maybe<StoryblokPreviewToken>;
  getTopSellingProducts?: Maybe<Array<Maybe<WineProduct>>>;
  getVismaOrderDetails?: Maybe<VismaOrderDetail>;
  getVismaOrderHistory: VismaOrderListPagination;
  isRpTokenValid?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAllCartRuleArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllClassicsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllCustomerProfileArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllCustomerProfileAddressArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllDiscountArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllGroupVoucherCodeArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllPriceTablePricingArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllRatingArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllRatingSymbolsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllReviewerArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllUnitArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllUrlRedirectArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllWineProductArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCityByZipcodeArgs = {
  countryCode: Scalars['String'];
  zipcode: Scalars['String'];
};


export type QueryGetCustomerUuidByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetGroupVoucherByCodeArgs = {
  groupVoucherCode: Scalars['String'];
};


export type QueryGetMagentoOrderArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrdersArgs = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate: Scalars['DateTime'];
  productSkus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate: Scalars['DateTime'];
  vismaProductIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetPageInfoArgs = {
  path: Scalars['String'];
  secret?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductByPathArgs = {
  path: Scalars['String'];
  secretKey?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductByVismaIdArgs = {
  id: Scalars['ID'];
  secretKey?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductsByIdsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryGetProductsByVismaIdsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryGetSessionArgs = {
  sessionSplitTests?: InputMaybe<Scalars['String']>;
};


export type QueryGetShippingMethodsArgs = {
  overrideCurrentDateAndTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetStoryblokPreviewTokenArgs = {
  space_id?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetVismaOrderDetailsArgs = {
  vismaOrderNumber?: InputMaybe<Scalars['Int']>;
};


export type QueryGetVismaOrderHistoryArgs = {
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryIsRpTokenValidArgs = {
  email: Scalars['String'];
  resetToken: Scalars['String'];
};

export type Rating = {
  __typename?: 'Rating';
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  /** Value can only be within '1-6' */
  maxRatingSymbols?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  rating?: Maybe<Scalars['Int']>;
  /** Value can only be within '1-6' or '80-100' */
  ratingPoint?: Maybe<Scalars['String']>;
  ratingSortWeight?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
  reviewer?: Maybe<Reviewer>;
  starCount?: Maybe<Scalars['Int']>;
  symbol?: Maybe<RatingSymbols>;
};

export type RatingSymbols = TaxonomyBase & {
  __typename?: 'RatingSymbols';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  vismaReference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type Redirect = {
  __typename?: 'Redirect';
  code?: Maybe<Scalars['Int']>;
  redirect?: Maybe<Scalars['String']>;
};

export type RedirectObject = {
  __typename?: 'RedirectObject';
  order?: Maybe<Order>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Reviewer = TaxonomyBase & {
  __typename?: 'Reviewer';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isVisibleOnline?: Maybe<Scalars['Boolean']>;
  machineName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  ratingType?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  vismaReference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type SessionResponse = {
  __typename?: 'SessionResponse';
  cart?: Maybe<Cart>;
  customerProfile?: Maybe<CustomerProfile>;
  groupVoucher?: Maybe<GroupVoucherCode>;
  /** Session ID */
  id?: Maybe<Scalars['ID']>;
  userSession?: Maybe<UserSession>;
};

export type ShippingMethod = {
  __typename?: 'ShippingMethod';
  carrier?: Maybe<Scalars['String']>;
  carrier_logo?: Maybe<Scalars['String']>;
  carrier_title?: Maybe<Scalars['String']>;
  deliveryComment?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dontDeliverDates?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  fastestDeliveryDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priceDescription?: Maybe<Scalars['String']>;
  showCustomDatePicker?: Maybe<Scalars['Boolean']>;
  showWhereToLeavePackage?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type SitemapEntry = {
  __typename?: 'SitemapEntry';
  changeFreq?: Maybe<Scalars['String']>;
  lastmod?: Maybe<Scalars['DateTime']>;
  path?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type Storyblok = PageInfoInterface & {
  __typename?: 'Storyblok';
  storyblok?: Maybe<Scalars['String']>;
};

export type StoryblokPreviewToken = {
  __typename?: 'StoryblokPreviewToken';
  accessToken?: Maybe<Scalars['String']>;
};

export type TaxonomyBase = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  vismaReference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TopCta = {
  __typename?: 'TopCta';
  icon?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Unit = AttributeBase & {
  __typename?: 'Unit';
  id?: Maybe<Scalars['String']>;
  machineName?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UrlRedirect = {
  __typename?: 'UrlRedirect';
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  redirect?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UserSession = {
  __typename?: 'UserSession';
  algoliaId?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['String']>;
  cookieConsent?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstUtm?: Maybe<Scalars['String']>;
  gaSessionId?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  latestFbClid?: Maybe<Scalars['String']>;
  latestUserAgent?: Maybe<Scalars['String']>;
  latestUtm?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['ID']>;
  superpayId?: Maybe<Scalars['String']>;
  /** A key-value array of test cases, used for A/B testing */
  testCases?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VismaOrderDetail = VismaOrderInterface & {
  __typename?: 'VismaOrderDetail';
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerAddressLine1?: Maybe<Scalars['String']>;
  customerAddressLine2?: Maybe<Scalars['String']>;
  customerAddressLine3?: Maybe<Scalars['String']>;
  customerCity?: Maybe<Scalars['String']>;
  customerCountryNumber?: Maybe<Scalars['Int']>;
  customerEanNumber?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPhone?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['Int']>;
  customerZipCode?: Maybe<Scalars['String']>;
  deliveryAddressLine1?: Maybe<Scalars['String']>;
  deliveryAddressLine2?: Maybe<Scalars['String']>;
  deliveryAddressLine3?: Maybe<Scalars['String']>;
  deliveryCity?: Maybe<Scalars['String']>;
  deliveryComment?: Maybe<Scalars['String']>;
  deliveryCountryNumber?: Maybe<Scalars['Int']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryName?: Maybe<Scalars['String']>;
  deliveryZipCode?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  finishedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoiceDate?: Maybe<Scalars['DateTime']>;
  lines: Array<VismaOrderLine>;
  orderStatus: Scalars['ID'];
  totalWithVat: Scalars['ID'];
  vatTotal?: Maybe<Scalars['Int']>;
  vismaOrderNumber: Scalars['ID'];
  webOrderId?: Maybe<Scalars['ID']>;
};

export type VismaOrderInterface = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderStatus: Scalars['ID'];
  totalWithVat: Scalars['ID'];
  vismaOrderNumber: Scalars['ID'];
  webOrderId?: Maybe<Scalars['ID']>;
};

export type VismaOrderLine = {
  __typename?: 'VismaOrderLine';
  id: Scalars['ID'];
  lineNumber: Scalars['Int'];
  productType?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalWithVat?: Maybe<Scalars['Int']>;
  unitPriceWithVat?: Maybe<Scalars['Int']>;
  unitVat?: Maybe<Scalars['Int']>;
  vatTotal?: Maybe<Scalars['Int']>;
  vismaOrderNumber: Scalars['ID'];
  vismaProductNumber: Scalars['ID'];
};

export type VismaOrderList = VismaOrderInterface & {
  __typename?: 'VismaOrderList';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderStatus: Scalars['ID'];
  totalWithVat: Scalars['ID'];
  vismaOrderNumber: Scalars['ID'];
  webOrderId?: Maybe<Scalars['ID']>;
};

export type VismaOrderListPagination = {
  __typename?: 'VismaOrderListPagination';
  items: Array<VismaOrderList>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type VoucherAddress = {
  deliveryAddress: Scalars['String'];
  deliveryCity: Scalars['String'];
  deliveryCompanyName?: InputMaybe<Scalars['String']>;
  deliveryCountry?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['Date']>;
  deliveryName: Scalars['String'];
  deliveryStreetFloor?: InputMaybe<Scalars['String']>;
  deliveryZip: Scalars['String'];
};

export type VoucherProduct = {
  discountPrice: Scalars['Int'];
  vismaId: Scalars['String'];
};

export type WineMeta = {
  __typename?: 'WineMeta';
  alcohol?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<Attribute>>>;
  categoryNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  classics?: Maybe<Array<Maybe<Classics>>>;
  contentLabel?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['String']>;
  contentsCl?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  energyLabel?: Maybe<Scalars['String']>;
  energyLabelDoc?: Maybe<Scalars['String']>;
  foods?: Maybe<Array<Maybe<Food>>>;
  grapes?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  port_type?: Maybe<Scalars['String']>;
  producerName?: Maybe<Scalars['String']>;
  productDataSheet?: Maybe<Scalars['String']>;
  productSubType?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  ratings?: Maybe<Array<Maybe<Rating>>>;
  savingPotential?: Maybe<Scalars['String']>;
  serving?: Maybe<Scalars['String']>;
  subDistrictName?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  units?: Maybe<Unit>;
  vintage?: Maybe<Scalars['Int']>;
  wineType?: Maybe<Scalars['String']>;
};

export type WineProduct = NodeBase & ProductBase & {
  __typename?: 'WineProduct';
  availableFrom?: Maybe<Scalars['DateTime']>;
  baPercentage?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  canBeAddedToCart?: Maybe<Scalars['Boolean']>;
  closure?: Maybe<Scalars['String']>;
  commercePrice: Scalars['Int'];
  costPrice?: Maybe<Scalars['Int']>;
  ctaHidePrice?: Maybe<Scalars['Boolean']>;
  ctaLink?: Maybe<Scalars['String']>;
  ctaStockText?: Maybe<Scalars['String']>;
  ctaTitle?: Maybe<Scalars['String']>;
  defaultQuantity?: Maybe<Scalars['Int']>;
  hideDiscountCountdown?: Maybe<Scalars['Boolean']>;
  hidePriceTable?: Maybe<Scalars['Boolean']>;
  hideReview?: Maybe<Scalars['Boolean']>;
  hideShippingCountdown?: Maybe<Scalars['Boolean']>;
  hideSupervinAdvantages?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** @deprecated Use 'images' instead */
  image?: Maybe<Image>;
  /** @deprecated Not used in Practice */
  imagePath?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Image>>>;
  inStock?: Maybe<Scalars['Boolean']>;
  isB2b?: Maybe<Scalars['Boolean']>;
  isBundleProduct?: Maybe<Scalars['Boolean']>;
  isLeftover?: Maybe<Scalars['Boolean']>;
  isSecret?: Maybe<Scalars['Boolean']>;
  isTicketsEnabled?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use 'body' instead */
  longText?: Maybe<Scalars['String']>;
  longTrimmedText?: Maybe<Scalars['String']>;
  matchingProducts?: Maybe<Array<Maybe<WineProduct>>>;
  meta?: Maybe<WineMeta>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  minimumQuantity?: Maybe<Scalars['String']>;
  /** @deprecated Use 'title' instead! */
  name?: Maybe<Scalars['String']>;
  nodePath?: Maybe<Scalars['String']>;
  nonAlcoholic?: Maybe<Scalars['Boolean']>;
  organic?: Maybe<Scalars['Boolean']>;
  packaging?: Maybe<Scalars['String']>;
  pageWhitelist?: Maybe<Array<Maybe<Scalars['String']>>>;
  pdfText?: Maybe<Scalars['String']>;
  preorderDate?: Maybe<Scalars['String']>;
  priceTable: PriceTable;
  productAuthor?: Maybe<ProductAuthor>;
  productId?: Maybe<Scalars['ID']>;
  productImageBadgeType?: Maybe<Scalars['String']>;
  promoteFrontPage?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  searchSortWeight?: Maybe<Scalars['String']>;
  searchWords?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
  shortText?: Maybe<Scalars['String']>;
  showSavings?: Maybe<Scalars['Boolean']>;
  sku: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
  suggestedRetailPrice?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierPhoneNumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<ProductTag>>>;
  title: Scalars['String'];
  topCta?: Maybe<TopCta>;
  typeId?: Maybe<ProductType>;
  urlRedirects?: Maybe<Array<Maybe<UrlRedirect>>>;
  vimeoId?: Maybe<Scalars['String']>;
  vismaProductNumber: Scalars['String'];
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type UpdateCartMutation = {
  __typename?: 'updateCartMutation';
  cart?: Maybe<Cart>;
  message?: Maybe<Scalars['String']>;
};
