import {computed, Inject, Injectable, PLATFORM_ID, Signal, signal, WritableSignal} from '@angular/core';
import {
  AuthenticateResponse,
  CustomerProfile,
  Maybe,
  Mutation,
  Query,
  SessionResponse
} from '../interfaces/generated/graphql';
import {catchError, map, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {CartService} from './cart.service';
import {isPlatformBrowser} from '@angular/common';
import {SuperpayService} from './superpay.service';
import {ApolloService} from './apollo.service';
import {FetchResult, NetworkStatus} from '@apollo/client/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupVoucherService} from './groupVoucher.service';
import {CookieService} from './cookie.service';
import {UnsubscribeService} from './unsubscribe.service';
import {UserInitService} from './user-init.service';
import {SessionService} from './session.service';
import {setTags, setUser} from '@sentry/angular-ivy';
import {SplitTestService} from '@core/services/split-test.service';
import {RoutingService} from '@core/services/routing.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {SuperpayEventService} from '@core/services/superpay-event.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<CustomerProfile | null> = new BehaviorSubject<CustomerProfile | null>(null);

  userSignal: Signal<CustomerProfile | null | undefined> = toSignal(this.currentUserSubject.asObservable());
  customerFirstnameSignal = computed(() => this.userSignal()?.fullName.split(' ')[0] ?? '');

  protected isAdminSignal: WritableSignal<boolean> = signal(false);

  userSub: Subscription;

  constructor(
    private apollo: ApolloService,
    private cartService: CartService,
    protected superpayService: SuperpayService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected http: HttpClient,
    protected modalService: NgbModal,
    protected groupVoucherService: GroupVoucherService,
    private cookieService: CookieService,
    protected unsubscribeService: UnsubscribeService,
    protected userInitService: UserInitService,
    private sessionService: SessionService,
    protected splitTestService: SplitTestService,
    private routingService: RoutingService,
    private superpayEventService: SuperpayEventService,
    protected router: Router,
  ) {
    this.createUserSub();

    if (isPlatformBrowser(this.platformId)) {
      // For easy debugging
      (window as any).setUserAdmin = (isAdmin: boolean) => {
        this.isAdminSignal.set(isAdmin);
      }

      this.superpayEventService.getSuperpayEvents().pipe(
        takeUntil(this.unsubscribeService.whileApplicationAlive()),
      ).subscribe(data => {
        if (data.event === 'auth-on-load') {
          this.superpayAuthOnLoad(data.data);
        }
        if (data.event === 'authentication') {
          this.superpayAuthenticationEvent(data.data);
        }
        if (data.event === 'open-reset-password') {
          this.forgottenPasswordSendEmailModal(data.data.email);
        }
      })
    }
  }

  isAdmin(): Signal<boolean> {
    return this.isAdminSignal;
  }

  protected hasAdminCookie() {
    return this.cookieService.get('supervin_was_admin');
  }

  triggerIsAdminCheck() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.hasAdminCookie()) {
      return;
    }
    this.http.get<string | {
      loggedIn: boolean
    }>(location.protocol + '//' + location.host + '/supervin_admin/efiware/check/isadmin', {withCredentials: true})
      .pipe(
        catchError(err => {
          if (!(err.status > 0)) {
            console.error(err);
          }
          return of({loggedIn: false});
        }),
        take(1)
      )
      .subscribe(res => {
        let obj = res;
        if (typeof obj === 'string') {
          if (obj.indexOf('{') !== 0) {
            return;
          }
          obj = JSON.parse(obj);
        }

        const isLoggedIn = (obj as any).loggedIn;

        this.isAdminSignal.set(!!isLoggedIn);
      })
  }

  getUser(): Observable<CustomerProfile | null> {
    return this.currentUserSubject.asObservable();
  }

  currentUser(): CustomerProfile | null {
    return this.currentUserSubject.getValue();
  }

  logout() {
    this.superpayService.superpayLogout();
    return this.logoutMutation();
  }

  logoutMutation() {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'signOutMutation'
    }).then(res => {
      const session = res.data?.signOutV2;
      this.updateOrSetUserToken();
      this.updateUser(session);
      if (this.router.url.includes('/user')) {
        this.router.navigateByUrl('/');
      }
    });
  }

  login(username: string, password: string): Promise<CustomerProfile | undefined> {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'authenticateV2Mutation',
      variables: {
        email: username,
        password
      }
    }).then(res => {
      const authResponse = res.data?.authenticateV2;
      this.updateUserWithAuthResponse(authResponse);
      return authResponse?.sessionResponse?.customerProfile || undefined;
    }).catch(err => {
      throw err;
    })
  }

  registerCustomer(email: string, fullname: string | undefined, password: string): Promise<CustomerProfile | undefined> {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'registerMutation',
      variables: {
        email: email,
        fullName: fullname,
        password: password
      }
    }).then(res => {
      const authResponse = res.data?.register;
      this.confirmAccountModal(email);
      this.updateUserWithAuthResponse(authResponse);
      return authResponse?.sessionResponse?.customerProfile || undefined;
    }).catch(err => {
      throw err;
    })
  }

  updateSessionAfterLoginWithPasskey(customerToken: string, sourceCartId: string): Promise<SessionResponse | null | undefined> {
    this.updateOrSetUserToken(customerToken);
    return this.updateSessionWithCustomerToken(customerToken, sourceCartId, true);
  }

  forgottenPasswordSendEmailModal(email: Maybe<string> | undefined = undefined) {
    this.modalService.dismissAll();
    import('@layout/components/modals/reset-password-send-email-modal/reset-password-send-email-modal.component').then(({ResetPasswordSendEmailModalComponent}) => {
      const modalReference = this.modalService.open(ResetPasswordSendEmailModalComponent);
      if (email) {
        modalReference.componentInstance.email = email;
      }
    });
  }

  forgottenPasswordCreatePasswordModal(email: string, rpToken: string) {
    this.modalService.dismissAll();
    import('@layout/components/modals/create-password-modal/create-password-modal.component').then(({CreatePasswordModalComponent}) => {
      const modalReference = this.modalService.open(CreatePasswordModalComponent);
      if (email) {
        modalReference.componentInstance.email = email;
        modalReference.componentInstance.rpToken = rpToken;
      }
    });
  }

  checkUrlForConfirmAccountKey() {
    const email = this.routingService.getQueryParams()['email'];
    const confirmationKey = this.routingService.getQueryParams()['confirmationKey'];
    if (!email || !confirmationKey) {
      return;
    }
    this.accountConfirmedModal(email, confirmationKey);
  }

  accountConfirmedModal(email: string, confirmationKey: string) {
    this.modalService.dismissAll();
    import('../../content/layout/components/modals/welcome-modal/welcome-modal.component').then(({WelcomeModalComponent}) => {
      const modalReference = this.modalService.open(WelcomeModalComponent);
      modalReference.componentInstance.email = email;
      modalReference.componentInstance.confirmationKey = confirmationKey;
    });
  }

  confirmAccountModal(email: string) {
    this.modalService.dismissAll();
    import('../../content/layout/components/modals/confirm-account-modal/confirm-account-modal.component').then(({ConfirmAccountModalComponent}) => {
      const modalReference = this.modalService.open(ConfirmAccountModalComponent);
      if (email) {
        modalReference.componentInstance.email = email;
      }
    });
  }

  accountNotConfirmedModal() {
    this.modalService.dismissAll();
    import('../../content/layout/components/modals/account-not-confirmed-modal/account-not-confirmed-modal.component').then(({AccountNotConfirmedModalComponent}) => {
      this.modalService.open(AccountNotConfirmedModalComponent);
    });
  }

  createPasswordLinkErrorModal(email: string, errorText?: string) {
    this.modalService.dismissAll();
    import('../../content/layout/components/modals/create-password-link-error-modal/create-password-link-error-modal.component').then(({CreatePasswordLinkErrorModalComponent}) => {
      const modalReference = this.modalService.open(CreatePasswordLinkErrorModalComponent);
      modalReference.componentInstance.email = email;
      if (errorText) {
        modalReference.componentInstance.errorText = errorText;
      }
    });
  }

  confirmAccount(email: string, confirmationKey: string): Promise<CustomerProfile | undefined> {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'confirmAccountMutation',
      variables: {
        email,
        confirmationKey
      }
    }).then(res => {
      const authResponse = res.data?.confirmAccount;
      this.updateUserWithAuthResponse(authResponse);
      return authResponse?.sessionResponse?.customerProfile || undefined;
    });
  }

  updateUserWithAuthResponse(authResponse: AuthenticateResponse | null | undefined) {
    this.updateOrSetUserToken(authResponse?.authToken);
    this.superpayService.setCustomerToken(authResponse?.authToken, authResponse?.sessionResponse?.userSession?.superpayId, authResponse?.sessionResponse?.cart?.cartId);
    this.updateUser(authResponse?.sessionResponse);
  }

  public changePassword(newPassword: string, email?: string, resetToken?: string) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'changePasswordMutation',
      variables: {
        newPassword: newPassword,
        email: email,
        resetToken: resetToken,
      }
    });
  }

  public sendEmailConfirmation(email: string): Promise<FetchResult<Mutation>> {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'sendAccountConfirmationEmailMutation',
      variables: {
        email
      }
    })
  }

  public sendResetPasswordEmailCode(email: string, type = 'resetPassword') {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'sendRpTokenMutation',
      variables: {
        email,
        type
      }
    })
  }

  protected updateUser(user: SessionResponse | null | undefined) {
    const parsed = user?.customerProfile || null;
    this.currentUserSubject.next(parsed?.id as any as number > 0 ? parsed : null);
    this.cartService.updateCart(user?.cart);
    this.groupVoucherService.setGroupVoucher(user?.groupVoucher || null);
    this.sessionService.setSession(user?.userSession);
    if (!user && this.router.url.includes('user')) {
      this.router.navigateByUrl('/');
    }
  }

  protected isSuccessPage() {
    const pathname = window.location.pathname;
    return pathname.indexOf('/checkout') !== -1 && pathname.indexOf('/success') !== -1;
  }

  get userToken() {
    return this.cookieService.get('userToken');
  }

  protected setUserToken(userToken: string) {
    const expiresDate = this.cookieService.getExpiresDate(365);
    this.cookieService.set('userToken', userToken, expiresDate, '/');
  }

  updateSession(sessionResponse: SessionResponse | null | undefined) {

    setUser({
      id: sessionResponse?.id ?? '',
    });

    setTags({
      sessionId: sessionResponse?.userSession?.sessionId ?? '',
      cartId: sessionResponse?.cart?.cartId,
      loggedIn: !!sessionResponse?.customerProfile,
    });

    this.updateUser(sessionResponse);
  }

  private createUserSub() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.isSuccessPage()) {
      this.cartService.dropCart();
    }

    const superpayCartId = this.superpayService.getSuperpayCartId();
    if (superpayCartId) {
      this.cartService.setCartId(superpayCartId);
    }

    this.userSub = this.apollo.watchQuery<Query>({
      queryName: 'getSessionQuery',
      variables: {
        sessionSplitTests: JSON.stringify(this.splitTestService.splitTestsVersions)
      }
    }).pipe(
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
      map(res => ({user: res.data.getSession || undefined, isLoaded: res.networkStatus === NetworkStatus.ready})),
      tap(({user, isLoaded}: { isLoaded: boolean, user: SessionResponse | undefined }) => {
        this.updateSession(user);
        this.splitTestService.setSplitTestCases(user?.userSession?.testCases);
        if (isLoaded) {
          this.userInitService.setInitialized();
        }
        if (user?.cart?.id) {
          this.cookieService.delete('cartId');
        }
      }),
    ).subscribe();
  }

  protected updateOrSetUserToken(userToken?: string | null) {
    if (userToken) {
      this.setUserToken(userToken);
    } else {
      this.removeUserToken();
    }
  }

  protected removeUserToken() {
    this.cookieService.delete('userToken');
  }

  public updateSessionWithCustomerToken(customerToken?: string, cartId?: string, mergeCart = false): Promise<SessionResponse | null | undefined> {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.resolve(null);
    }

    return this.apollo.mutatePromise<Mutation>({
      queryName: 'updateSessionWithCustomerTokenMutation',
      variables: {
        customerToken,
        cartId,
        mergeCart
      },
    }).then(data => {
      this.cartService.updateCart(data.data?.updateSessionWithCustomerToken?.cart);
      this.updateUser(data.data?.updateSessionWithCustomerToken);
      return data.data?.updateSessionWithCustomerToken;
    });
  }

  public isResetPasswordTokenValid(email: string, resetToken: string): Promise<boolean> {
    return this.apollo.mutatePromise<Query>({
      queryName: 'isRpTokenValidQuery',
      variables: {
        email,
        resetToken
      }
    }).then(res => {
      return res.data?.isRpTokenValid || false;
    });
  }

  public getCustomerUUIDByEmail(email: string) {
    return this.apollo.queryPromise({
      queryName: 'getCustomerUUIDByEmailQuery',
      variables: {
        email: email
      }
    }).then((res: any) => res.data.getCustomerUuidByEmail);
  }

  private superpayAuthenticationEvent(data: any) {
    const customerToken = data.customerToken ?? null;
    const sourceCartId = data.sourceCartId ?? null;
    if (data.authenticationType === 'logout') {
      this.logoutMutation();
    }
    if (data.authenticationType === 'login') {
      this.updateOrSetUserToken(customerToken);
      this.updateSessionWithCustomerToken(customerToken, sourceCartId);
    }
  }

  private superpayAuthOnLoad(data: any) {
    const customerToken = data.customerToken ?? null;
    const sourceCartId = data.sourceCartId ?? null;
    if (customerToken && !this.userToken) {
      this.updateOrSetUserToken(customerToken);
      this.updateSessionWithCustomerToken(customerToken, sourceCartId);
    }
  }

}
