<div [class.isPrinting]="printService.isPrinting()" [class.simple-header]="isCheckout()" class="top-content">
  <app-header></app-header>
  <app-breadcrumbs data-rr-shared="true" class="hide-content-mobile"></app-breadcrumbs>
  <div class="animation-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
<footer [class.isPrinting]="printService.isPrinting()" app-footer id="footer"
        role="contentinfo"></footer>
<app-toast *ngIf="showToast()" aria-atomic="true" aria-live="polite" class="toast-position"></app-toast>
<app-superpay-buy-btn type="authentication"></app-superpay-buy-btn>
