import {Component, computed, EventEmitter, input, Output, signal,} from '@angular/core';
import {GroupVoucherCode, WineProduct} from '@core/interfaces/generated/graphql';
import {Pricing, ProductService} from '@core/services/product.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddToCartComponent} from '../add-to-cart/add-to-cart.component';
import {AddToCartPopupComponent} from '../add-to-cart/add-to-cart-popup/add-to-cart-popup.component';
import {AsyncPipe, CurrencyPipe, JsonPipe} from '@angular/common';
import {GroupVoucherService} from '@core/services/groupVoucher.service';
import {PricePipe} from '@core/pipes/price.pipe';
import {TrackEventService} from '@core/services/track-event.service';

export type PriceLinePrefix = [string, string] | [string];

export interface PriceLine {
  isDiscounted: boolean;
  prefix?: PriceLinePrefix | undefined
  label: string;
  price: number;
  currencyCode: string;
  isStriked?: boolean;
  showVatText?: boolean;
}

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, AsyncPipe, AddToCartComponent, AddToCartPopupComponent, PricePipe, JsonPipe]
})
export class PriceTableComponent {

  alwaysShowDiscountBtn = input<boolean>(true);
  hideProductDefaultCta = input<boolean | null>(false);
  eventSourceName = input.required<string>();
  hitPosition = input<number>();
  hideAddToCart = input<boolean>(false);
  product = input.required<WineProduct | undefined>();

  priceLines = computed(() => {
    const product = this.product();
    if (!product) {
      return [];
    }
    const groupVoucher = this.groupVoucherService.groupVoucherSignal().groupVoucher;
    const lines = this.productService.getFormattedProductPricesSignal(this.product)();
    return this.formatPriceLines(groupVoucher, product, lines);
  });

  @Output() addedToCart: EventEmitter<any> = new EventEmitter<any>();

  public showAddedToCart = signal(false);
  addedToCartTimeout: any;

  constructor(
    private productService: ProductService,
    private modal: NgbModal,
    private groupVoucherService: GroupVoucherService,
    private trackEventService: TrackEventService
  ) {
  }

  get groupVoucher() {
    return this.groupVoucherService.currentGroupVoucher();
  }

  hasGroupVoucherPrice = computed(() => {
    const product = this.product()
    return !!this.groupVoucher?.prices?.find(d => d?.vismaId == product?.vismaProductNumber)
  })

  getUnitName(product: WineProduct) {
    return product?.meta?.units?.shortName;
  }

  public priceToPriceLines(price: Pricing, product: WineProduct): PriceLine[] | undefined {
    const lines = [] as PriceLine[];
    const label = `${price.minQty} ${this.getUnitName(product) || ''} pr. stk.`;
    const isDiscounted = !!price.discount || !!price.source;
    const currencyCode = price.currencyCode;
    const showVatText = price.showVatText;

    let defaultPriceIncVat = price?.amountIncVat as string | number | undefined;

    if (!defaultPriceIncVat || !currencyCode) {
      return undefined;
    }

    if (typeof defaultPriceIncVat === 'string') {
      defaultPriceIncVat = parseInt(defaultPriceIncVat, 10);
    }

    let bestPriceIncVat = price.discount?.amountIncVat || defaultPriceIncVat;
    let prefix: PriceLinePrefix | undefined = undefined;

    if (isDiscounted) {
      prefix = price?.source ? ['PRIS', price.source] : ['BEDSTE', 'PRIS'];
    }

    if (isDiscounted && price.strikeThrough && (!price.discount?.strikeThrough || product?.defaultQuantity === 1)) {
      lines.push({
        isDiscounted: false,
        label,
        currencyCode,
        price: defaultPriceIncVat,
        isStriked: true,
      })
    }

    lines.push({
      isDiscounted,
      label,
      currencyCode,
      price: bestPriceIncVat,
      prefix,
      showVatText,
      isStriked: isDiscounted ? !!price.discount?.strikeThrough : price.strikeThrough,
    })

    return lines;
  }

  formatPriceLines(groupVoucher: GroupVoucherCode | null, product: WineProduct, prices: Pricing[]) {
    const lines = [] as PriceLine[];

    const mapped = prices.map(price => this.priceToPriceLines(price, product)).reduce((prev, curr) => {
      if (curr) {
        prev?.push(...curr);
      }
      return prev
    }, []) as PriceLine[];

    const suggestedRetailPrice = (product as WineProduct).suggestedRetailPrice;
    if (suggestedRetailPrice && groupVoucher && groupVoucher.showSuggestedRetailPrice) {
      return this.getSuggestedRetailLines(mapped, suggestedRetailPrice);
    }

    lines.push(...mapped);

    return lines;
  }

  protected getSuggestedRetailLines(mapped: PriceLine[], suggestedRetailPrice: string) {
    const lines = [] as PriceLine[];
    lines.push({
      isDiscounted: false,
      label: 'Vejl. salgspris',
      price: parseInt(suggestedRetailPrice, 10),
      currencyCode: 'DKK'
    });

    const price = mapped.pop();
    if (price) {
      lines.push(price)
    }
    return lines;
  }

  addedToCartEvent($event: boolean) {
    if (!$event) {
      return;
    }
    this.showAddedToCart.set(true);
    this.addedToCart.emit();
    this.addedToCartTimeout = setTimeout(() => {
      this.showAddedToCart.set(false);
    }, 2500);
  }

  buyMore() {
    clearTimeout(this.addedToCartTimeout);
    this.showAddedToCart.set(false);
  }

  openContactForOffersModal() {
    this.trackEventService.trackClickEvent('contact-for-offers', {
      product: this.product()?.name,
      source: this.eventSourceName(),
      vismaId: this.product()?.vismaProductNumber
    });
    import('../../modals/basic-page-modal/basic-page-modal.component').then(comp => {
      const ref = this.modal.open(comp.BasicPageModalComponent);
      (ref.componentInstance).path = 'info/storkoeb';
    })
  }
}

